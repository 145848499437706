import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useProfile } from "../context/ProfileContext";

const PublicProfile = () => {
  const { fetchUserProfile } = useProfile(); // Fetch profile from context
  const { username } = useParams(); // Get the username from the URL
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserProfile = async () => {
      const profileData = await fetchUserProfile(username);
      setUserProfile(profileData);
      setLoading(false);
    };
    loadUserProfile();
  }, [username, fetchUserProfile]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userProfile) {
    return <div>User not found.</div>;
  }

  return (
    <div className="container mx-auto p-6 max-w-4xl">
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center space-x-6">
          {/* Profile Picture */}
          <div className="w-32 h-32 rounded-full bg-gray-200 overflow-hidden">
            {userProfile.photo ? (
              <img
                src={userProfile.photo}
                alt={`${userProfile.displayName}'s profile`}
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="text-gray-400 text-xl">No Photo</span>
            )}
          </div>

          {/* Profile Details */}
          <div>
            <h1 className="text-3xl font-bold">{userProfile.displayName}</h1>
            <p className="text-gray-500">{userProfile.username}</p>
            <p className="text-gray-700 mt-4">{userProfile.bio || "No bio available."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;
