import React from "react";
import { Link } from "react-router-dom";

const Post = ({ post }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 border relative">
      <div className="flex items-center mb-4">
        <Link
          to={`/user/${post.username}`}
          className="w-14 h-14 rounded-full overflow-hidden bg-gray-200 flex-shrink-0"
        >
          {post.photo ? (
            <img
              src={post.photo}
              alt={`${post.username}'s profile`}
              className="object-cover w-full h-full"
            />
          ) : (
            <span className="text-gray-400 flex items-center justify-center h-full">
              No Photo
            </span>
          )}
        </Link>
        <div className="ml-4 flex flex-col">
          <Link
            to={`/user/${post.username}`}
            className="hover:underline text-lg font-semibold"
          >
            {post.displayName || "Unknown User"}
          </Link>
          <Link
            to={`/user/${post.username}`}
            className="text-sm text-gray-500 hover:underline"
          >
            @{post.username}
          </Link>
        </div>
        <div className="absolute top-4 right-4 text-gray-400 text-sm">
          {post.createdAt}
        </div>
      </div>
      {post.title && <h2 className="text-xl font-bold text-gray-800 mb-2">{post.title}</h2>}
      <p className="text-gray-700 mb-4">{post.description}</p>
      {post.image && (
        <img
          src={post.image}
          alt="Post Content"
          className="w-full h-60 object-cover rounded-lg mb-4"
        />
      )}
    </div>
  );
};

export default Post;
