import React, { useState, useEffect } from "react";
import axios from "../config/axiosInstance";
import { useProfile } from "../context/ProfileContext";
import Post from "./Post";

const Feed = () => {
  const { profile } = useProfile();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPost, setNewPost] = useState({ title: "", description: "", image: null });

  // Reusable function to load posts
  const loadPosts = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get("/posts");
      // Sort posts by createdAt in descending order
      const sortedPosts = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setPosts(sortedPosts);
    } catch (error) {
      console.error("Error loading posts:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Fetch posts on component mount
  useEffect(() => {
    loadPosts();
  }, []);

  // Handle new post creation
  const handleCreatePost = async () => {
    if (!profile?.userId) {
      alert("You must be logged in to create a post.");
      return;
    }

    const formData = new FormData();
    formData.append("title", newPost.title);
    formData.append("description", newPost.description);
    formData.append("userId", profile.userId);
    if (newPost.image) formData.append("image", newPost.image);

    try {
      const response = await axios.post("/posts", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Post Created:", response.data);
      setNewPost({ title: "", description: "", image: null }); // Reset form
      await loadPosts(); // Refresh the feed after creating a post
    } catch (error) {
      console.error("Error creating post:", error);
      alert("Failed to create post. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Feed</h1>

      {/* Button to manually reload posts */}
      <button
        onClick={loadPosts}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mb-6"
      >
        Refresh Feed
      </button>

      {/* Show loading state */}
      {loading ? (
        <div className="text-gray-500 text-center">Loading feed...</div>
      ) : (
        <>
          {/* New Post Section */}
          {profile?.userId && (
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <h2 className="text-2xl font-bold mb-4">Create a New Post</h2>
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={newPost.title}
                onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg mb-4"
              />
              <textarea
                name="description"
                placeholder="What's on your mind?"
                value={newPost.description}
                onChange={(e) => setNewPost({ ...newPost, description: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg mb-4"
                rows="4"
              ></textarea>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setNewPost({ ...newPost, image: e.target.files[0] })}
                className="mb-4"
              />
              <button
                onClick={handleCreatePost}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Post
              </button>
            </div>
          )}

          {/* Posts Section */}
          <div className="grid gap-6">
            {posts.map((post) => (
              <Post key={post.postId} post={post} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Feed;
