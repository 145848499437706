import React, { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Profile from "./components/Profile";
import Feed from "./components/Feed";
import PublicProfile from "./components/PublicProfile";

const App = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();


  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]); // Set the active account if not already set
      console.log("Active account set:", accounts[0]);
    }
  }, [accounts, instance]);

  const login = () => {
    instance.loginPopup().catch((error) => console.error(error));
  };

  const logout = () => {
    instance.logoutPopup().catch((error) => console.error(error));
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gray-100 ">
        {/* Navbar */}
        <nav className="w-full bg-blue-600 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Redirect to homepage when clicking "Impactable" */}
        <Link to="/" className="text-2xl font-bold hover:underline">
          Impactable
        </Link>
        {isAuthenticated ? (
          <div className="flex items-center space-x-4">
            <Link
              to="/feed"
              className="bg-blue-800 px-4 py-2 rounded-md hover:bg-blue-700 transition"
            >
              Feed
            </Link>
            <Link
              to="/profile"
              className="bg-blue-800 px-4 py-2 rounded-md hover:bg-blue-700 transition"
            >
              Profile
            </Link>
            <button
              className="bg-blue-800 px-4 py-2 rounded-md hover:bg-blue-700 transition"
              onClick={logout}
            >
              Log Out
            </button>
          </div>
        ) : (
          <button
            className="bg-blue-800 px-4 py-2 rounded-md hover:bg-blue-700 transition"
            onClick={login}
          >
            Sign Up
          </button>
        )}
      </div>
    </nav>
  
        {/* Routes */}
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/feed" />
                ) : (
                  <div className="landing-page">
                    <header className="hero-section bg-gradient-to-r from-blue-600 to-blue-400 text-white py-20 px-4">
                      <div className="hero-content container mx-auto text-center">
                        <h1 className="text-5xl font-extrabold mb-6">
                          Connect. Collaborate. Innovate.
                        </h1>
                        <p className="text-xl mb-8">
                          Join the ultimate platform for developers and investors to build, grow, 
                          and revolutionize ideas together.
                        </p>
                        <button
                          className="cta-btn bg-white text-blue-600 px-8 py-4 text-lg font-bold rounded-full shadow-lg hover:bg-blue-50 transition"
                          onClick={login}
                        >
                          Get Started
                        </button>
                      </div>
                    </header>
  
                    <section className="features-section py-16 bg-gray-100">
                      <div className="container mx-auto px-6 text-center">
                        <h2 className="text-3xl font-extrabold text-gray-800 mb-12">
                          Why Choose Impactable?
                        </h2>
                        <div className="features grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                          <div className="feature bg-white p-6 rounded-lg shadow-md transition hover:shadow-lg">
                            <h3 className="text-2xl font-bold text-blue-500 mb-4">Connect</h3>
                            <p className="text-gray-600">
                              Network with developers and investors ready to innovate.
                            </p>
                          </div>
                          <div className="feature bg-white p-6 rounded-lg shadow-md transition hover:shadow-lg">
                            <h3 className="text-2xl font-bold text-blue-500 mb-4">Collaborate</h3>
                            <p className="text-gray-600">
                              Work together on groundbreaking projects that matter.
                            </p>
                          </div>
                          <div className="feature bg-white p-6 rounded-lg shadow-md transition hover:shadow-lg">
                            <h3 className="text-2xl font-bold text-blue-500 mb-4">Innovate</h3>
                            <p className="text-gray-600">
                              Bring ideas to life with the right tools and people.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )
              }
            />
  
            <Route
              path="/feed"
              element={
                <ProtectedRoute>
                  <Feed />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <div className="container mx-auto p-6">
                    <h1 className="text-2xl font-bold mb-4">Your Profile</h1>
                    <Profile />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route path="/user/:username" element={<PublicProfile />} /> {/* Public profile route */}

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
  
        {/* Footer */}
        <footer className="footer py-6 bg-blue-600">
          <div className="container mx-auto text-center">
            <p className="text-white text-sm">© 2024 Impactable. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
  
};

export default App;
