import React, { useState } from "react";
import { useProfile } from "../context/ProfileContext";

const CustomizeProfile = ({ toggleEditing }) => {
  const { profile, saveProfile } = useProfile(); // Access profile and saveProfile function
  const [formState, setFormState] = useState({
    displayName: profile?.displayName || "",
    username: profile?.username || "",
    bio: profile?.bio || "",
    photo: profile?.photo || null, // Include photo in form state
  });
  const [previewPhoto, setPreviewPhoto] = useState(profile?.photo || null); // Local preview for photo upload

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewPhoto(reader.result); // Set preview for photo
        setFormState((prev) => ({
          ...prev,
          photo: reader.result, // Update form state with base64 photo
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Save the updated profile to the database
    try {
      await saveProfile({
        ...profile, // Existing profile data
        ...formState, // Updated fields
      });
      toggleEditing(); // Close the edit form
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
      <form onSubmit={handleSave}>
        {/* Profile Picture */}
        <div className="mb-6">
          <label className="block text-gray-700 font-bold mb-2">Profile Photo</label>
          <div className="w-32 h-32 rounded-full bg-gray-200 flex justify-center items-center overflow-hidden">
            {previewPhoto ? (
              <img
                src={previewPhoto}
                alt="Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="text-gray-400 text-xl">Add Photo</span>
            )}
            <input
              type="file"
              accept="image/*"
              className="absolute opacity-0 cursor-pointer"
              onChange={handlePhotoUpload}
            />
          </div>
        </div>

        {/* Display Name */}
        <div className="mb-4">
          <label htmlFor="displayName" className="block text-gray-700 font-bold mb-2">
            Display Name
          </label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            value={formState.displayName}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>

        {/* Username */}
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 font-bold mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formState.username}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
          />
        </div>

        {/* Bio */}
        <div className="mb-4">
          <label htmlFor="bio" className="block text-gray-700 font-bold mb-2">
            Bio
          </label>
          <textarea
            id="bio"
            name="bio"
            value={formState.bio}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            rows="4"
          ></textarea>
        </div>

        {/* Save and Cancel Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={toggleEditing}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomizeProfile;
