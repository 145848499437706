import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/index.css"; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/msalConfig";
import { ProfileProvider } from "./context/ProfileContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MsalProvider instance={msalInstance}>
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
