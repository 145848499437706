import React from "react";
import { Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const ProtectedRoute = ({ children }) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  console.log("ProtectedRoute: Active Account:", account);

  return account ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
