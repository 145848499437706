import React, { useState, useEffect, useCallback } from "react";
import { useProfile } from "../context/ProfileContext";
import CustomizeProfile from "./CustomizeProfile";
import { useMsal } from "@azure/msal-react";
import axiosInstance from "../config/axiosInstance";
import Post from "./Post";

const Profile = () => {
  const { profile, setProfile, saveProfile } = useProfile();
  const [posts, setPosts] = useState([]); // State for posts
  const { accounts } = useMsal();
  const [isEditing, setIsEditing] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState(null); // Local preview for uploads
  const [showFullImage, setShowFullImage] = useState(false); // Full-size image toggle

  // Automatically set Display Name from MSAL if not already set
  useEffect(() => {
    if (accounts && accounts.length > 0 && profile) {
      const msalName = accounts[0]?.name || accounts[0]?.username || "@user";
      if (!profile.displayName || profile.displayName === "New User") {
        setProfile((prev) => ({ ...prev, displayName: msalName }));
      }
    }
  }, [accounts, profile, setProfile]);


  const fetchUserPosts = useCallback(async () => {
    if (profile?.username) {
      try {
        const postsResponse = await axiosInstance.get(`/posts`, {
          params: { username: profile.username }, // Pass username to backend
        });
  
        // Ensure posts are sorted by createdAt in descending order
        const sortedPosts = postsResponse.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPosts(sortedPosts); // Update posts state
      } catch (error) {
        console.error("Error fetching user posts:", error);
      }
    }
  }, [profile?.username]); // Add profile?.username as a dependency
  
  useEffect(() => {
    fetchUserPosts();
  }, [fetchUserPosts]);
  
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewPhoto(reader.result); // Set local preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmPhoto = async () => {
    if (previewPhoto) {
      await saveProfile({ ...profile, photo: previewPhoto }); // Save to backend
      setPreviewPhoto(null); // Clear local preview
    }
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handlePhotoClick = () => {
    if (profile.photo) {
      // If photo exists, show full-size image
      setShowFullImage(true);
    }
  };

  if (!profile) {
    return (
      <div className="container mx-auto p-6 max-w-4xl">
        <h1 className="text-3xl font-bold">Loading Profile...</h1>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 max-w-4xl">
      {/* Profile Header */}
      <div className="bg-white shadow-md rounded-lg p-6 relative">
        <div className="flex items-center space-x-6">
          {/* Profile Picture */}
          <div className="relative w-32 h-32 rounded-full bg-gray-200 flex justify-center items-center overflow-hidden">
            {profile.photo ? (
              <img
                src={profile.photo}
                alt="Profile"
                className="w-full h-full object-cover cursor-pointer"
                onClick={handlePhotoClick}
              />
            ) : (
              <>
                <span className="text-gray-400 text-xl">Add Photo</span>
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handlePhotoUpload}
                />
              </>
            )}
          </div>

          {/* Profile Details */}
          <div>
            <h1 className="text-3xl font-bold">{profile.displayName || "New User"}</h1>
            <p className="text-gray-500">{profile.username || "@user"}</p>
            <p className="text-gray-700 mt-4">{profile.bio || "Add a bio to your profile."}</p>

            {/* Edit Profile Button */}
            <button
              onClick={toggleEditing}
              className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4 hover:bg-blue-700 transition"
            >
              Edit Profile
            </button>
          </div>
        </div>

        {/* Confirm Photo Button */}
        {previewPhoto && (
          <div className="mt-4">
            <button
              onClick={handleConfirmPhoto}
              className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition"
            >
              Confirm
            </button>
          </div>
        )}

        {/* Full-Size Image Overlay */}
        {showFullImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
            onClick={() => setShowFullImage(false)}
          >
            <img
              src={profile.photo}
              alt="Full Size"
              className="max-w-full max-h-full"
            />
          </div>
        )}
      </div>

      {/* Additional Profile Sections */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Posts</h2>
        {posts.length > 0 ? (
          <div className="grid gap-6">
            {posts.map((post) => (
              <Post key={post.postId} post={post} /> // Render each post using Post.js
            ))}
          </div>
        ) : (
          <div className="bg-white shadow-md rounded-lg p-6">
            <p className="text-gray-500">No posts yet. Start creating amazing content!</p>
          </div>
        )}
      </div>

      {/* Profile Editing Form */}
      {isEditing && (
        <div className="mt-8">
          <CustomizeProfile toggleEditing={toggleEditing} />
        </div>
      )}
    </div>
  );
};

export default Profile;
