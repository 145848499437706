import React, { createContext, useContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "../config/axiosInstance"; // Custom Axios instance

// Create Profile Context
const ProfileContext = createContext();

// Profile Provider Component
export const ProfileProvider = ({ children }) => {
  const { accounts } = useMsal(); // Access MSAL accounts
  const [profile, setProfile] = useState(null);

  // Fetch profile from backend when the component mounts or accounts change
  useEffect(() => {
    const fetchProfile = async () => {
      if (accounts && accounts.length > 0) {
        const userId = accounts[0]?.localAccountId; // MSAL user ID
        try {
          const response = await axios.get(`/profile/${userId}`);
          console.log("Fetched Profile:", response.data); // Check if data is fetched
          if (response.data) {
            setProfile(response.data); // Only set if data exists
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      }
    };
  
    fetchProfile();
  }, [accounts]);
  // Save updated profile to backend
  const saveProfile = async (updatedProfile) => {
    if (accounts && accounts.length > 0) {
      const userId = accounts[0]?.localAccountId; // Ensure we use the MSAL user ID
      try {
        const response = await axios.post("/profile", {
          ...updatedProfile,
          userId, // Attach the userId to the payload
        });
        console.log(response.data.message); // Log success message
        setProfile(updatedProfile); // Update the profile state locally
      } catch (error) {
        console.error("Error saving profile:", error);
      }
    }
  };

  const fetchUserProfile = async (username) => {
    try {
      const response = await axios.get(`/profile/${username}`);
      return response.data; // Return the fetched user profile
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // Provide the profile data and methods to child components
  return (
    <ProfileContext.Provider value={{ profile, setProfile, saveProfile, fetchUserProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

// Custom Hook to Use Profile Context
export const useProfile = () => useContext(ProfileContext);
