import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // Ensure this is defined
    authority: process.env.REACT_APP_AUTHORITY, // Ensure this is defined
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin, // Provide a fallback
  },
  cache: {
    cacheLocation: "localStorage", // or sessionStorage
    storeAuthStateInCookie: true, // Recommended for IE11 or older browsers
  },
};
console.log("Client ID:", process.env.REACT_APP_CLIENT_ID);
console.log("Authority:", process.env.REACT_APP_AUTHORITY);
console.log("Redirect URI:", process.env.REACT_APP_REDIRECT_URI);
export const msalInstance = new PublicClientApplication(msalConfig);
